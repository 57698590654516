
















































































import { Component, Vue } from 'vue-property-decorator'
import { apiDailyDeliveryRecord } from '@/api/finance/finance'
import downloadExcel from 'vue-json-excel'
import { funDate } from '@/utils/util'

@Component({
    components: {
        downloadExcel,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {}
    timeData = []
    // 顶部查询表单
    form = {
        day_time: '',
    }
    loading = false
    exportData: any = [ ] // 导出的表格数据
    tableData: any = [] // 显示的表格数据
    // 头部文字
    header_text:any = ''
    json_fields = {
        "订单编号": {
            field: 'sn',
            callback: (value: any) => {
                return '&nbsp;' + value
            }
        },    //常规字段
        "收货小区":"community_name",
        "收货信息":"user",
        "配送产品":"goods_name",
        "数量":"num",
        "应销售价格（元）":"single_price",
        "实收单价（扣除优惠）元":"single_pay_price",
        "成本价（元）":"single_cost_price",
        "配送费（元）":"deliver_fee",
        "佣金（元）":"earnings",
        "出库单价（元）": "outgoing_unit_price",
        "出库总价（元）": "total_delivery_price"
    }

    
    /** E Data **/


    /** S Methods **/
  
    // 获取数据
    getData() {
        this.getExportData()
    }
    // 获取导出数据
    getExportData() {
        this.tableData = []
        this.exportData = []
        this.loading = true
        apiDailyDeliveryRecord({
            ...this.form
        }).then(res => {
            this.loading = false
            console.log("res", res)
            this.exportData = [...res]
            this.tableData = [...res]
            console.log("this.tableData", this.tableData)
            let totle_obj:any = {
                sn: '合计',
                community_name: '',
                user: '',
                goods_name: '',
                num: 0,
                single_price: 0,
                single_pay_price:0,
                single_cost_price:0,
                deliver_fee:0,
                earnings:0,
                outgoing_unit_price: 0,
                total_delivery_price:0
            }
            res.forEach((item:any) => {
                item.sn = item.sn.toString()
                totle_obj.num+= Number(item.num)
                totle_obj.single_price+= Number(item.single_price)
                totle_obj.single_pay_price+= Number(item.single_pay_price)
                totle_obj.single_cost_price+= Number(item.single_cost_price)
                totle_obj.deliver_fee+= Number(item.deliver_fee)
                totle_obj.earnings+= Number(item.earnings)
                totle_obj.outgoing_unit_price+= Number(item.outgoing_unit_price)
                totle_obj.total_delivery_price+= Number(item.total_delivery_price)

                console.log("item", item)
            })

            totle_obj.single_price.toFixed(2);
            totle_obj.single_pay_price.toFixed(2);
            totle_obj.single_cost_price.toFixed(2);
            totle_obj.deliver_fee.toFixed(2);
            totle_obj.earnings.toFixed(2);
            totle_obj.outgoing_unit_price.toFixed(2);
            totle_obj.total_delivery_price.toFixed(2);
            this.exportData.push({ ...totle_obj})
            console.log("this.exportData", this.exportData)
        })
    }

    // 重置
    onReset() {
        this.form.day_time = funDate(0)
        this.getExportData()
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.form.day_time = funDate(0)
        this.getExportData()
    }
    /** E Life Cycle **/
}
